import * as React from "react";
import { Box, Flex } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";

let Button = styled.a`
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 67, 84, 0.9);
  padding: 6px;
  text-decoration: none;
  margin-right: 6px;
  border-radius: 6px;
  min-width: 24px;
  display: inline-block;
  text-align: center;
  &:hover {
    background-color: #dee3ec;
  }
`;

let ButtonDisabled = styled.span`
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 67, 84, 0.9);
  background-color: #f0f2f5;
  padding: 6px;
  margin-right: 6px;
  border-radius: 6px;
  min-width: 24px;
  display: inline-block;
  text-align: center;
`;

interface PaginationProps {
  sourceUrl: string;
  currentPage: number;
  numPages: number;
}

const Pagination: React.FC<PaginationProps> = props => {
  const { sourceUrl, currentPage, numPages } = props;
  if (numPages === 1) return <p></p>;
  const links = [...Array(numPages + 1)].map((_, i) => {
    if (i === 0) {
      return;
    }
    if (i === currentPage || (currentPage === 1 && i === 1)) {
      return (
        <li key={i}>
          <ButtonDisabled>{i}</ButtonDisabled>
        </li>
      );
    }
    if (i === 1) {
      return (
        <li key={i}>
          <Button href={sourceUrl}>{i}</Button>
        </li>
      );
    }
    return (
      <li key={i}>
        <Button href={`${sourceUrl}/page/${i}`}>{i}</Button>
      </li>
    );
  });
  return (
    <ul
      style={{
        display: "flex",
        listStyle: "none",
        margin: "0",
        paddingInlineStart: "0",
        justifyContent: "center",
      }}
    >
      {links}
    </ul>
  );
};

export default Pagination;
