import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import Breadcrumbs, { Crumb } from "../components/breadcrumbs";
import TopArticle from "../components/top-article";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import PostInRow from "../components/cards/postInRow";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Pagination from "../components/pagination";
import useTranslation from "../hooks/useTranslation";
import { getOneTrustId } from '../utils/one-trust-ids';

const StyledHeading = styled(Heading)`
  font-weight: 600;
  color: #2d384d;
  font-size: 32px;
  line-height: 40px;
  margin: 26px 0 14px 0;
  @media (min-width: 600px) {
    font-size: 36px;
    line-height: 40px;
    margin: 18px 0 14px 0;
  }
  @media (min-width: 1024px) {
    font-size: 45px;
    line-height: 56px;
  }
`;

const AllPostsTitle = styled.h3`
  color: #3a4354;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

interface BlogProps {
  data: any;
  pageContext: any;
}

const BlogCategory: React.FC<BlogProps> = props => {
  const {
    data: {
      contentfulCategory: {
        name,
        slug,
        locale,
        topArticle,
        topArticleImage,
        description: { description: description },
      },
      allContentfulPost: { nodes: posts },
    },
    pageContext: { numPosts, currentPage, numPages },
  } = props;
  const crumbs = [
    {
      // TODO: the string for "Home" should come from Contentful according to locale
      name: "Home",
      to: `/`,
      item: `${locale.domain}`,
    },
    {
      name: name,
      item: `${locale.domain}/${slug}`,
    } as Crumb,
  ];

  const Articles = posts.map((post, index) => (
    <Grid.Item columns={[12, 12, 6, 3]} key={index}>
      <PostInRow
        post={post}
        hideDivider={posts.length - 1 == index}
        staticImage={null}
      ></PostInRow>
    </Grid.Item>
  ));

  return (
    <Layout locale={locale.slug}>
      <Helmet
        htmlAttributes={{
          lang: locale.languageCode,
        }}
        title={name + " | " + locale.name}
        titleTemplate={name + " | " + locale.name}
        link={[
          {
            rel: "canonical",
            key: locale.domain + "/" + slug,
            href: locale.domain + "/" + slug,
          },
        ]}
        meta={[
          { name: `description`, content: description },
          { name: `robots`, content: `index, follow` },
          { name: `og:locale`, content: locale.languageCode },
          { name: `og:type`, content: `object` },
          { name: `og:title`, content: name + " | " + locale.name },
          { name: `og:description`, content: description },
          { name: `og:site_name`, content: locale.name },
          { name: `og:url`, content: locale.domain + "/" + slug },
          { name: `og:image`, content: topArticleImage.asset.fluid.src },
          { name: `twitter:card`, content: topArticleImage.asset.fluid.src },
          { name: `twitter:title`, content: name + " | " + locale.name },
          { name: `twitter:site`, content: "@" + locale.twitterAccountName },
        ]}
      >
        {locale.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(locale.slug)} ></script>}
        {locale.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Grid.Row>
        <Grid.Item columns={[12, 12, 12, 12]}>
          <Breadcrumbs crumbs={crumbs} />
          <StyledHeading size={1}>{name}</StyledHeading>
          <Body size={["lg", "lg", "lg", "xl"]} mb="lg">
            {description}
          </Body>
        </Grid.Item>
      </Grid.Row>
      <Grid.Row>
        <Grid.Item>
          <TopArticle
            post={topArticle}
            image={topArticleImage}
            isHomepage={false}
          />
        </Grid.Item>
      </Grid.Row>
      <Grid.Row mt={["136px", "80px", "78px", "46px"]}>
        <Grid.Item>
          <AllPostsTitle>
            {`${useTranslation(
              locale.slug,
              "taxonomy.all_articles"
            )} (${numPosts})`}
          </AllPostsTitle>
          <Divider
            color="#D8D8D8"
            mt={{ _: "xxs", md: "xxs", lg: "xxs" }}
            mb={{ _: "xs", md: "xs", lg: "xs" }}
          />
        </Grid.Item>
      </Grid.Row>
      <Grid.Row mb="xxxl">{Articles}</Grid.Row>
      <Grid.Row mb="xxxl">
        <Grid.Item>
          <Pagination
            sourceUrl={`/${slug}`}
            currentPage={currentPage}
            numPages={numPages}
          />
        </Grid.Item>
      </Grid.Row>
    </Layout>
  );
};

export const query = graphql`
  fragment postFields on ContentfulPost {
    slug
    title
    previewImage {
      description
      asset {
        fluid(maxWidth: 536) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    featureImage {
      description
      asset {
        fluid(maxWidth: 536) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    description {
      description
    }
    category {
      name
      slug
    }
    locale {
      domain
      slug
    }
    timeToRead
  }
  query($slug: String!, $localeSlug: String!, $skip: Int!, $limit: Int!) {
    contentfulCategory(
      slug: { eq: $slug }
      locale: { slug: { eq: $localeSlug } }
    ) {
      name
      slug
      updatedAt
      description {
        description
      }
      topArticle {
        ...postFields
      }
      topArticleImage {
        description
        asset {
          fluid(maxWidth: 476) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
      locale {
        name
        slug
        domain
        languageCode
        twitterAccountName
        logo {
          fluid {
            src
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        imageUrl
        lang
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        locale: { slug: { eq: $localeSlug } }
        category: { slug: { eq: $slug } }
      }
    ) {
      nodes {
        ...postFields
      }
    }
  }
`;

export default BlogCategory;
